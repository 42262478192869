import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify';

import axios from '../services/axios'
import Breadcrumb from './breadcrumb'

import '../stylesheets/form.scss'
import '../stylesheets/tenant.scss'
import 'react-toastify/dist/ReactToastify.css';

// Buttons
import Button from '@material-ui/core/Button';

// Select
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


class AddTenant extends Component {
    
    state = {
        data: {
            name:'',
            first_name: '',
            last_name: '',
            company_name: '',
            company_size: null,
            contact_email:'',
            contact_number:'',
            user_limit: 10,
            trial_expires_at: new Date().getTime(),
            suspended_at: new Date(),
            schema:'',
            per_license_cost: 200,
            currency: 'INR'
        },
        errors: {
            name: null,
            first_name: null,
            last_name: null,
            company_name: null,
            company_size: null,
            schema: null,
            contact_email:null,
            user_limit:null,
            trial_expires_at:null,
            contact_number: null,
            suspended_at: null,
            per_license_cost: null,
        },
        defaultCurrency: 'INR',
        currencyList: ['INR'],
        isDisabled: true,
    }

    handleUpdateName = e => {
        if (!e.target.value) {
            let errors = this.state.errors;
            errors['name'] = 'Name can not be left blank';
            this.setState({errors})
        } else {
            let errors = this.state.errors;
            errors['name'] = null; 
            this.setState({errors})
        }
        axios.get(`/tenant/doesExist?name=${e.target.value}`)
            .then(response => {
                if(response.data.doesExist){
                    this.setState({isDisabled:true})
                }else{
                    this.setState({isDisabled:false})
                }
            })
            .catch(e => {
                console.log(e, 2);
                this.setState({isDisabled:true})
            })
        this.setState({ data: { ...this.state.data, name: e.target.value } });
        this.isValid();
    }

    handleUpdateFirstName = e => {
        if (!e.target.value) {
            let errors = this.state.errors;
            errors['first_name'] = 'First Name can not be left blank';
            this.setState({errors})
        } else {
            let errors = this.state.errors;
            errors['first_name'] = null; 
            this.setState({errors})
        }
        this.setState({ data: { ...this.state.data, first_name: e.target.value } });
        this.isValid();
    }

    handleUpdateLastName = e => {
        if (!e.target.value) {
            let errors = this.state.errors;
            errors['last_name'] = 'Last Name can not be left blank';
            this.setState({errors})
        } else {
            let errors = this.state.errors;
            errors['last_name'] = null; 
            this.setState({errors})
        }
        this.setState({ data: { ...this.state.data, last_name: e.target.value } });
        this.isValid();
    }

    handleUpdateCompanyName = e => {
        if (!e.target.value) {
            let errors = this.state.errors;
            errors['company_name'] = 'Company Name can not be left blank';
            this.setState({errors})
        } else {
            let errors = this.state.errors;
            errors['company_name'] = null; 
            this.setState({errors})
        }
        this.setState({ data: { ...this.state.data, company_name: e.target.value } });
        this.isValid();
    }

    handleUpdateCompanySize = e => {
        let value = parseInt(e.target.value || 0)
        if (value >= 0 && value <= 10000) {
            this.setState({ data: { ...this.state.data, company_size: value } })
        }

        if(e.target.value<1 || e.target.value>9999){
            this.setState({
                ...this.state.errors,
                company_size:'Company Size must be between 1 and 99999'
            })
        }else{
            this.setState({
                ...this.state.errors,
                company_size:null
            })
        }
        this.isValid();
    }

    handleUpdateContactEmail = e => {
        if (e.target.value || e.target.value==='') {
            this.setState({ data: { ...this.state.data, contact_email: e.target.value } })
        }
        function validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
        if(!validateEmail(e.target.value) || !e.target.value){
            this.setState({
               errors:{
                ...this.state.errors,
                contact_email:'Please Enter a valid Email'
               }
            })
        }else{
            this.setState({
                errors:{
                    ...this.state.errors,
                    contact_email:null
                   }
            })
        }
        this.isValid();
    }

    handleUpdateContactNumber = e => {
        this.setState({data: {...this.state.data, contact_number: e.target.value}});
        this.isValid();
    }

    handleUpdateUserLimit = e => {
        let value = parseInt(e.target.value || 0)
        if (value >= 0 && value <= 10000) {
            this.setState({ data: { ...this.state.data, user_limit: value } })
        }

        if(e.target.value<1 || e.target.value>9999){
            this.setState({
                ...this.state.errors,
                user_limit:'User Limit must be between 1 and 99999'
            })
        }else{
            this.setState({
                ...this.state.errors,
                user_limit:null
            })
        }
        this.isValid();
    }

    handleUpdateExpiresAt = e => {
        if((new Date(e.target.value)).toString() === 'Invalid Date'){
            return
        }
        if (e.target.value)
            this.setState({ data: { ...this.state.data, trial_expires_at: new Date(e.target.value).getTime() } })

        if(new Date(e.target.value).getTime() < new Date().getTime()){
            this.setState({
               errors:{
                ...this.state.errors,
                trial_expires_at:'Please ensure that trail expiry date is after today'
               }
            })
        }else{
            this.setState({
                errors:{
                    ...this.state.errors,
                trial_expires_at:null
                }
            })
        }
        this.isValid();
    }

    handleUpdateSchemaName = e => {
        if (!e.target.value) {
            let errors = this.state.errors;
            errors['schema'] = 'Schema can not be left blank';
            this.setState({errors})
        }
        this.setState({ data: { ...this.state.data, schema: e.target.value } });
        this.isValid();
    }

    handleLicenseChange = (e) => {
        let errors = this.state.errors;
        if(e.target.value > 0){
            errors['per_license_cost'] = 'License cost must be greater than 0'
            this.setState({errors})
        }else{
             this.setState({
                errors:{
                    ...this.state.errors,
                per_license_cost:null
                }
            });
        }
        this.isValid();
    }

    handleChangeCurrency = (e) => {
        let currency = e.target.value;
        let data = this.state.data;
        data['currency'] = currency;
        this.setState({data: data});
    }

    setSuspendedTime = (e) => {
        if((new Date(e.target.value)).toString() === 'Invalid Date'){
            return
        }
        this.setState({ data: { ...this.state.data, suspended_at: e.target.value } })
        if(this.state.data.trial_expires_at && new Date(e.target.value).getTime() < new Date(this.state.data.trial_expires_at).getTime()){
            let errors = this.state.errors;
            errors['suspended_at'] = 'Please ensure that suspended date is greater than trail period';
            this.setState({
               errors
            });
        } else {
            this.setState({
                errors:{
                    ...this.state.errors,
                suspended_at:null
                }
            });
        }
        this.isValid();
    }


    isValid = () => {
        let isDisabled = false;
        for (const key in this.state.errors) {
            if(this.state.errors[key] !== null) {
                isDisabled = true;
                break;
            }
        }
        this.setState({ isDisabled });
    }

    handleCancelClick = () => {
        this.props.history.push(`/tenant`)
    }

    handleSaveClick = () => {
        this.setState({isDisabled:true});
        this.toastId = toast(" ⏳ Creating new tenant. Please wait...", { 
            autoClose: false ,
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
        });

        axios.post(`/tenant/`, this.state.data)
            .then(response => {
                toast.update(this.toastId, {
                    render : " 🚀 Tenant Created Successfully!",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000 ,
                    onClose: () => this.props.history.push(`/tenant`)
                });
            })
            .catch(e=>{
                this.setState({isDisabled:false})
                let error = 'Generic Error';
                if (e) {
                    if (e && e.response && e.response.data && e.response.data.error && e.response.data.error.message)
                    if(Array.isArray(e.response.data.error.message)){
                        let message = ''
                        for(let item of e.response.data.error.message){
                            for(let o in item){
                                message += `${o} : ${item[o]}\n`
                            }
                        }
                        error = message
                    }else{
                        error = e.response.data.error.message
                    }
                    else {
                        error = e.message
                    }
                }
                toast.update(this.toastId, {
                    render : ' ⚠️ ' + error,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR,
                    autoClose: false
                });
            })
    }

    render() {
        return (
            <div className='page-container'>
                {
                        <>
                            <Breadcrumb data={[
                                {
                                    text: 'Tenant',
                                    link: '/tenant'
                                }, {
                                    text: 'add',
                                    link: null
                                }
                            ]} />
                            <div className='tenant-page-container'>
                                <form className="tenant-page-form">
                                    <div className='form-item'>
                                        <label htmlFor="name" className='form-label'>Name<sup>*</sup></label>
                                        <input type='text' id='name' className={`form-input ${this.state.errors.name?'error':''}`} placeholder='name' aria-label='name' name='name' value={this.state.data.name} onChange={this.handleUpdateName}/>
                                        {this.state.errors.name && <label htmlFor="name" className={`form-label-error`}>{this.state.errors.name}</label>} 
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor="firstname" className='form-label'>First Name<sup>*</sup></label>
                                        <input type='text' id='firstname' className={`form-input ${this.state.errors.first_name?'error':''}`} placeholder='first name' aria-label='firstname' name='firstname' value={this.state.data.first_name} onChange={this.handleUpdateFirstName}/>
                                        {this.state.errors.first_name && <label htmlFor="firstname" className={`form-label-error`}>{this.state.errors.first_name}</label>} 
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor="lastname" className='form-label'>Last Name<sup>*</sup></label>
                                        <input type='text' id='lastname' className={`form-input ${this.state.errors.last_name?'error':''}`} placeholder='last name' aria-label='lastname' name='lastname' value={this.state.data.last_name} onChange={this.handleUpdateLastName}/>
                                        {this.state.errors.last_name && <label htmlFor="lastname" className={`form-label-error`}>{this.state.errors.last_name}</label>} 
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor="companyName" className='form-label'>Company Name<sup>*</sup></label>
                                        <input type='text' id='companyName' className={`form-input ${this.state.errors.company_name?'error':''}`} placeholder='company name' aria-label='companyName' name='companyName' value={this.state.data.company_name} onChange={this.handleUpdateCompanyName}/>
                                        {this.state.errors.company_name && <label htmlFor="companyName" className={`form-label-error`}>{this.state.errors.company_name}</label>} 
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor="companySize" className={`form-label ${this.state.errors.company_size?'error':''}`}>Company Size<sup>*</sup></label>
                                        <input type='number' id='companySize' className='form-input' placeholder='0' aria-label='companySize' name='companySize' value={this.state.data.company_size} onChange={this.handleUpdateCompanySize} />
                                        {this.state.errors.company_size && <label htmlFor="companySize" className={`form-label-error`}>{this.state.errors.company_size}</label>}
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor="email" className='form-label'>Email<sup>*</sup></label>
                                        <input type='email' id='email' className={`form-input ${this.state.errors.contact_email?'error':''}`} placeholder='email' aria-label='email' name='email' value={this.state.data.contact_email} onChange={this.handleUpdateContactEmail} />
                                        {this.state.errors.contact_email && <label htmlFor="email" className={`form-label-error`}>{this.state.errors.contact_email}</label>}
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor="contact" className='form-label'>Contact No.<sup>*</sup></label>
                                        <input type='tel' id='contact_number' className={`form-input ${this.state.errors.contact_number?'error':''}`} placeholder='contact' aria-label='contact' name='contact' value={this.state.data.contact_number} pattern="[0-9]{3} [0-9]{3} [0-9]{4}" maxLength="15" onChange={this.handleUpdateContactNumber} />
                                        {this.state.errors.contact_number && <label htmlFor="contact_number" className={`form-label-error`}>{this.state.errors.contact_number}</label>}
                                    </div>
                                    <div className='form-item'>
                                        <label htmlFor="userLimit" className={`form-label ${this.state.errors.user_limit?'error':''}`}>User Limit<sup>*</sup></label>
                                        <input type='number' id='userLimit' className='form-input' placeholder='0' aria-label='userLimit' name='userLimit' value={this.state.data.user_limit} onChange={this.handleUpdateUserLimit} />
                                        {this.state.errors.user_limit && <label htmlFor="userLimit" className={`form-label-error`}>{this.state.errors.user_limit}</label>}
                                    </div>

                                    <div className='form-item'>
                                        <label htmlFor="licenseCost" className={`form-label  ${this.state.errors.per_license_cost?'error':''}`}>Per License Cost<sup>*</sup></label>
                                        <input type='number' id='licenseCost' className={`form-input`} placeholder='Per license cost' aria-label='licenseCost' name='licenseCost' value={this.state.data.per_license_cost} onChange={this.handleLicenseChange} />
                                        {this.state.errors.per_license_cost && <label htmlFor="licenseCost" className={`form-label-error`}>{this.state.errors.per_license_cost}</label>}
                                    </div>

                                     <div className='currencyInput form-item'>
                                        <label htmlFor="currencyInput" className={`form-label`}>Currency<sup>*</sup></label>
                                        <Select id="currencyInput" value={this.state.defaultCurrency} onChange={this.handleChangeCurrency}>
                                            {this.state.currencyList.map((list, index) => {
                                                return <MenuItem key={index} value={list}>{list}</MenuItem>
                                            })}
                                        </Select>
                                    </div>

                                    <div className='form-item'>
                                        <label htmlFor="expiryTime" className={`form-label  ${this.state.errors.per_license_cost?'error':''}`}>Trial Expires at<sup>*</sup></label>
                                        <input type='date' id='expiryTime' className='form-input' aria-label='expiryTime' name='expiryTime' value={new Date(this.state.data.trial_expires_at).toISOString().substr(0, 10)} onChange={this.handleUpdateExpiresAt}/>
                                        {this.state.errors.trial_expires_at && <label htmlFor="expiryTime" className={`form-label-error`}>{this.state.errors.trial_expires_at}</label>}
                                    </div>

                                    <div className='form-item'>
                                        <label htmlFor="suspendedTime" className={`form-label`}>Suspended at<sup>*</sup></label>
                                        <input onChange={this.setSuspendedTime} type='date' id='suspendedTime' className={`form-input  ${this.state.errors.suspended_at?'error':''}`} placeholder='suspendedTime' aria-label='suspendedTime' name='suspendedTime' value={this.state.data.suspended_at ? new Date(this.state.data.suspended_at).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)} />
                                        {this.state.errors.suspended_at && <label htmlFor="suspendedTime" className={`form-label-error`}>{this.state.errors.suspended_at}</label>}
                                        
                                    </div>

                                    <div className='form-item'>
                                        <label htmlFor="schemaName" className='form-label'>Schema name<sup>*</sup></label>
                                        <input type='text' id='schemaName' className={`form-input  ${this.state.errors.schema?'error':''}`} placeholder='schema name' aria-label='schemaName' name='schemaName' value={this.state.data.schema} onChange={this.handleUpdateSchemaName}/>
                                        {this.state.errors.schema && <label htmlFor="schemaName" className={`form-label-error`}>{this.state.errors.schema}</label>}
                                    </div>
                                    <div className='form-item button'>
                                        <div className='button-wrapper-with-margin'>
                                            <Button color="default" onClick={this.handleCancelClick}>
                                              Cancel
                                            </Button>

                                            <Button color="primary" variant="contained" id='button-save' disabled={this.state.isDisabled} onClick={this.handleSaveClick}>
                                              Save
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <ToastContainer autoClose={2000} />
                        </>
                }
            </div>
        )
    }
}

export default AddTenant
