import React, { Component } from 'react';
import '../stylesheets/other.scss';
import Button from '@material-ui/core/Button';
import '../stylesheets/form.scss';
import moment from 'moment';
import axios from '../services/axios';
import exportAsExcelFile from '../services/exportToExcel';

class OtherComponent extends Component {
  state = {
    from: new Date(`${new Date().getMonth()}-${new Date().getDate()}-${new Date().getFullYear()}`),
    to: new Date(),
    monthlyCountRequest: false,
  };
  setDates = (e) => {
    const name = e.target.name;
    this.setState({ [name]: new Date(e.target.value) });
  };
  handleGetClick = () => {
    this.setState({ monthlyCountRequest: true });
    axios
      .post(`/analytics/getMonthlyCount`, this.state)
      .then(({ data }) => {
        const result = [[]];
        let sequenceOfColumns = ['Tenant', 'Total Active'];
        const listName = 'Active Counts';
        result[0][0] = Object.keys(data).map((tenant) => {
          const obj = {};
          let tenantObj = data[tenant];
          obj['Tenant'] = tenant;
          obj['Total Active'] = tenantObj.filter((users) => users['active_days']).length;
          return obj;
        });
        exportAsExcelFile(
          result,
          sequenceOfColumns,
          'Monthly Active Count from ' +
            moment(new Date(this.state.from)).format('DD-MMM-YYYY') +
            ' to ' +
            moment(new Date(this.state.to)).format('DD-MMM-YYYY'),
          [listName]
        );
        this.setState({ monthlyCountRequest: false });
      })
      .catch((err) => {
        console.log({ err });
        this.setState({ monthlyCountRequest: false });
      });
  };
  render() {
    const { from, to, monthlyCountRequest } = this.state;
    return (
      <div className="other-page-container">
        <div className="first-section">
          <div className="card-view monthly-count-view">
            <div className="card-title">Get Monthly Active Count of All Tenants</div>
            <div className="form-block">
              <div className="form-item">
                <label htmlFor="from" className={`form-label`}>
                  From<sup>*</sup>
                </label>
                <input
                  onChange={this.setDates}
                  type="date"
                  id="from"
                  className={`form-input`}
                  placeholder="from"
                  aria-label="from"
                  name="from"
                  value={from ? moment(from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                />
              </div>

              <div className="form-item">
                <label htmlFor="to" className={`form-label`}>
                  To<sup>*</sup>
                </label>
                <input
                  onChange={this.setDates}
                  type="date"
                  id="to"
                  className={`form-input`}
                  placeholder="to"
                  aria-label="to"
                  name="to"
                  value={to ? moment(to).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                />
              </div>
              <div className="form-item-getbtn">
                <Button
                  color="primary"
                  variant="contained"
                  id="button-save"
                  disabled={monthlyCountRequest}
                  onClick={this.handleGetClick}
                >
                  {monthlyCountRequest ? 'Wait...' : 'Get'}
                </Button>
              </div>
            </div>
          </div>
          <div className="card-view multiselect-tenant-view">
            <div className="card-title">Get Monthly Active Detailed View of Tenants</div>
            <div className="multi-tenantview-block"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default OtherComponent;
