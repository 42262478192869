import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import '../stylesheets/breadcrumb.scss'

class Breadcrumb extends Component {

    state = {
        ...this.props
    }

    render() {
        let elements = [];
        this.state.data.forEach((element, index) => {

            if (element.link) {
                elements.push(

                    <div key={index} className='crumbs crumbs-link'>
                        <NavLink className='nav-link' to={element.link}>
                            {element.text}
                        </NavLink>
                    </div >

                )
            } else {
                elements.push(
                    <div key={index} className='crumbs'>
                        {element.text}
                    </div>
                )
            }

            if (index !== this.state.data.length - 1) {
                elements.push(
                    <div key={'ooo' + index} className='crumbs-seperator'>
                        &gt;
                    </div>
                )
            }
        })

        return (
            <div className='bread-crumb'>
                {elements}
            </div>
        )
    }
}


export default Breadcrumb