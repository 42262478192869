import axios from 'axios';
import { STAGING_API, MAIN_API, LOCALHOST_API } from '../config.js';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

axios.interceptors.request.use(function (config) {
  // handling route prefix '/'
  config.url = config.url[0] === '/' ? config.url.slice(1, config.url.length) : config.url;

  if (window.location.href.match('staging-manage.flowace.in')) {
    // link Endpoint with every route
    config.url = [STAGING_API, config.url].join('');
  } else if (window.location.href.match('localhost')) {
    config.url = [LOCALHOST_API, config.url].join('');
  } else {
    config.url = [MAIN_API, config.url].join('');
  }

  // each request token validation
  const token = cookies.get('token');
  if (token) config.headers.Authorization = token;

  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  (err) => {
    const { status } = err.response;
    if (status === 401 || status === 403) {
      cookies.remove('token');
      return (window.location = '/login');
    } else {
      return err;
    }
  }
);

export default axios;
