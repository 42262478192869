import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from '../services/axios';
import 'react-table/react-table.css';
import '../stylesheets/tenantlist.scss';
import { debounce } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

// Buttons
import Button from '@material-ui/core/Button';

// Loader
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import exportAsExcelFile from '../services/exportToExcel';
const ColorCircularProgress = withStyles({
  root: {
    color: '#f07539',
  },
})(CircularProgress);

class AdminEmails extends Component {
  state = {
    data: null,
    fetchingLoader: false,
    totalUsers: 0,
    adminRequest: false
  };

  componentDidMount() {
    axios.get('/router/procedure/export_active_tenant_active_admin').then((response) => {
      if (response.data) {
      }
      this.setState({ data: response.data });
      this.setState({ totalUsers: response.data.reduce((prev, next) => prev + next.user_limit, 0) });
    });
  }

  handleTenantSearch = debounce((query) => {
    // cancel the previous request
    if (typeof this._source != typeof undefined) {
      this._source.cancel('Operation canceled due to new request.');
    }

    // save the new request for cancellation
    this._source = axios.CancelToken.source();

    this.setState({ fetchingLoader: true });
    axios
      .get(`/router/procedure/export_active_tenant_active_admin`, { cancelToken: this._source.token })
      .then((response) => {
        let searchData = response.data
        searchData = response.data.filter((record) => {
            return (
                record["Tenant"].toLowerCase().indexOf(query.toLowerCase()) > -1
              );
        });
        this.setState({ fetchingLoader: false });
        this.setState({ data: searchData });
        this.setState({ totalUsers: searchData.reduce((prev, next) => prev + next.user_limit, 0) });
      })
      .catch((error) => {
        this.setState({ fetchingLoader: false });
        if (axios.isCancel(error)) {
          console.log('Request canceled', error);
        } else {
          console.log(error);
        }

        toast.error(' ⚠️ Issue Occured!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    }, 200);

  exportEmails = (e) => {
    this.setState({ adminRequest: true });
    axios
      .get('/router/procedure/export_active_tenant_active_admin')
      .then(({ data }) => {
        const result = [[]];
        let sequenceOfColumns = ['Name', 'Period State', 'First Name', 'Last Name', 'Emails'];
        const listName = 'Admin Emails';
        result[0][0] = Object.keys(data).map((tenant) => {
          const obj = {};
          obj['Name'] = data[tenant]['Tenant'];
          obj['PeriodState'] = data[tenant]['PeriodState'];
          obj['FirstName'] = data[tenant]['FirstName'];
          obj['LastName'] = data[tenant]['LastName'];
          obj['Emails'] = data[tenant]['EmailId'];
          return obj;
        });
        exportAsExcelFile(
          result,
          sequenceOfColumns,
          'Admin Emails',
          [listName]
        );
        this.setState({ adminRequest: false });
      })
      .catch((err) => {
        console.log({ err });
        this.setState({ adminRequest: false });
      });
  };

  render() {
    const columns = [
      {
        Header: `Name`,
        accessor: 'Tenant',
        width: 200
      },
      {
        Header: 'Period',
        accessor: 'PeriodState',
        width: 130
      },
      {
        Header: 'First Name',
        accessor: 'FirstName'
      },
      {
        Header: 'Last Name',
        accessor: 'LastName'
      },
      {
        Header: 'Emails',
        accessor: 'EmailId'
      },
    ];
    const { adminRequest } = this.state;

    return (
      <div>
        {this.state.data ? (
          <div className="tenant">
            <div className="table-wrapper">
              <div className="pre-table-div">
                <div className="pre-table-div-1">
                  <p>
                    Tenants{' '}
                    <span className="tenant-count">
                      {this.state.data && this.state.data.length !== 0 ? this.state.data.length : ''}
                    </span>
                  </p>
                </div>
                <div className="pre-table-div-2">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">Search...</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      onChange={(e) => this.handleTenantSearch(e.target.value)}
                      startAdornment={
                        !this.state.fetchingLoader ? (
                          <InputAdornment position="start">
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="start">
                            <IconButton>
                              <div className="inlineSearchLoader">
                                <ColorCircularProgress />
                              </div>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      labelWidth={60}
                    />
                  </FormControl>
                    <div className="button-wrapper-with-margin">
                        <Button
                            color="primary"
                            variant="contained"
                            id="button-save"
                            disabled={adminRequest}
                            onClick={this.exportEmails}
                        >
                            {adminRequest ? 'Wait...' : 'Export'}
                        </Button>
                    </div>
                </div>
              </div>
              <ReactTable
                data={this.state.data}
                columns={columns}
                minRows={0}
                showPagination={this.state.data && this.state.data.length > 15 ? true : false}
                showPaginationTop={false}
                showPaginationBottom={this.state.data && this.state.data.length > 15 ? true : false}
                showPageSizeOptions={true}
              />
            </div>
          </div>
        ) : (
          <div className="progressBar">
            <ColorCircularProgress />
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}

export default AdminEmails;
