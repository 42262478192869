import React, { Component } from 'react'
import ReactTable from 'react-table'
import axios from '../services/axios'
import 'react-table/react-table.css'
import '../stylesheets/xRay.scss';
import { debounce } from "lodash";
import { ToastContainer } from 'react-toastify';

// Loader
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const ColorCircularProgress = withStyles({
  root: {
    color: '#f07539',
  },
})(CircularProgress);

class XRay extends Component {

    state = {
        data: null,
        rowCount: 0
    }

    componentDidMount() {
        axios.get('/xray')
            .then(response => {
                this.setState({ data: response.data })
            })
    }

    countRows = debounce((query) => {
        this.setState({ rowCount: query.length });
    }, 500);

    getDate() {
        let today = new Date();
        let daysToSelect = 1;
        today.setDate(today.getDate() - daysToSelect);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        let yyyy = today.getFullYear();
        return today = yyyy + '-' + mm + '-' + dd;
    }

    render() {
        const columns = [
            {
                Header: `Origin`,
                accessor: 'origin',
                style: { minWidth : '20%' },
                Cell: props => { return <span style={{ height: 4 }} title={props.value}>{props.value.split('.')[0].split('//')[1]}</span> },
                Footer: (props) => {
                    this.countRows(props.data);
                    return true;
                  }
            },
            {
                Header: 'Identity',
                accessor: 'identity',
                Cell: props => {
                    return <span title={props.value}>{props.value}</span>;
                }
            },
            {
                Header: 'Platform',
                accessor: 'platform',
                style: { minWidth : '20%' },
                Cell: props => {
                    return <span title={props.value}>{props.value}</span>;
                },
            },
            {
                Header: 'Version',
                accessor: 'version',
                style: { minWidth : '20%' },
                Cell: props => {
                    return <span title={props.value}>{props.value}</span>;
                }
            },
            {
                Header: 'Timestamp',
                accessor: 'timestamp_update',
                Cell: props => { return <span style={{ height: 4 }}  title={props.value} className={props.value > this.getDate(1) ? 'status green' : 'status red'}>{(new Date(props.value)).toLocaleString()}</span> },
                
            }
        ]

        return (
            <div>
                {
                    this.state.data 
                    ?
                        <div className="tenant">
                            <div className='table-wrapper'>
                                <div className='pre-table-div'>
                                    <div className='pre-table-div-1'>
                                        <p >
                                            X-Ray <span className="tenant-count">{this.state.rowCount ? this.state.rowCount : '' }</span>
                                        </p>
                                    </div>
                                </div>
                                <ReactTable
                                    data={this.state.data}
                                    columns={columns}
                                    minRows={0}
                                    showPagination={this.state.data && this.state.data.length > 15 ? true : false}
                                    showPaginationTop={false}
                                    showPaginationBottom={this.state.data && this.state.data.length > 15 ? true : false}
                                    showPageSizeOptions={true}
                                    className="-striped -highlight"
                                    filterable
                                    defaultFilterMethod={(filter, row, column) => {
                                        const id = filter.pivotId || filter.id;
                                        if (typeof filter.value === "object") {
                                          return row[id] !== undefined
                                            ? filter.value.toLowerCase().indexOf(row[id].toLowerCase()) > -1
                                            : true;
                                        } else {
                                            if(id ==='timestamp_update') {
                                                let date = new Date(row[id])
                                                return row[id] !== undefined
                                                ? String(date.toLocaleString()).indexOf(filter.value.toLowerCase()) > -1
                                                : true;
                                            } else {
                                                if( row[id] !== undefined ) {
                                                 return String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) > -1
                                                } else {
                                                    return true;
                                                }
                                            }
                                        }
                                      }}
                                      defaultSorted={[
                                        {
                                          id: "timestamp_update"
                                        }
                                      ]}
                                />
                            </div>
                        </div> 
                    :
                        <div className="progressBar"><ColorCircularProgress /></div>
                }
            <ToastContainer />
            </div>
        )
    }
}

export default XRay
