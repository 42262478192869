import React, { Component } from 'react';

class Status extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <iframe
          style={{ border: 0, width: '100vw', height: 'calc(100vh - 60px)' }}
          src="https://oneuptime.com/status-page/b1bc4754-e239-44a0-9a04-18ec85120b78"
          title="status-page"
        ></iframe>
      </div>
    );
  }
}

export default Status;
