import React, { Component } from 'react';
import ReactTable from 'react-table';
import axios from '../services/axios';
import moment from 'moment';
import 'react-table/react-table.css';
import '../stylesheets/tenantlist.scss';
import { debounce } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import exportAsExcelFile from '../services/exportToExcel';

// Tooltip
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

// Buttons
import Button from '@material-ui/core/Button';

// Checkbox
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
// Loader
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const ColorCircularProgress = withStyles({
  root: {
    color: '#f07539',
  },
})(CircularProgress);

class TenantList extends Component {
  state = {
    data: null,
    fetchingLoader: false,
    totalUsers: 0,
    tenantRequest: false,
    floatButton: false,
    checkBoxValues: [],
    selectedSchema: [],
    openMenuValues: [],
    openDrop: {},
    openMenu: false,
    isDialog: false,
    showDialog: false,
    reason: '',
    errors: {
      reason_field: null,
    },
    isDisabled: true,
    tenantName: '',
  };

  componentDidMount() {
    axios.get('/tenant').then((response) => {
      if (response.data) {
      }
      this.setState({ data: response.data });
      this.setState({ totalUsers: response.data.reduce((prev, next) => prev + next.user_limit, 0) });
    });
  }

  handleTenantSearch = debounce((query) => {
    // cancel the previous request
    if (typeof this._source != typeof undefined) {
      this._source.cancel('Operation canceled due to new request.');
    }

    // save the new request for cancellation
    this._source = axios.CancelToken.source();

    this.setState({ fetchingLoader: true, checkBoxValues: [], selectedSchema: [], floatButton: false });
    // setTimeout(() => {
    axios
      .get(`/tenant?search=${query}`, { cancelToken: this._source.token })
      .then((response) => {
        this.setState({ fetchingLoader: false });
        this.setState({ data: response.data });
        this.setState({ totalUsers: response.data.reduce((prev, next) => prev + next.user_limit, 0) });
      })
      .catch((error) => {
        this.setState({ fetchingLoader: false });
        if (axios.isCancel(error)) {
          console.log('Request canceled', error);
        } else {
          console.log(error);
        }

        toast.error(' ⚠️ Issue Occured!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    // }, 1000);

    /*
        this.setState({ fetchingLoader: true })

        axios.get(`/tenant?search=${query}`)
            .then(response => {
                this.setState({ fetchingLoader: false })
                this.setState({ data: response.data })
            })
            .catch(error => {
                this.setState({ fetchingLoader: false })
            })
            */
  }, 200);

  addNewTenant = (e) => {
    this.props.history.push('/tenant/add');
  };

  exportTenants = (e) => {
    this.setState({ tenantRequest: true });
    axios
      .get('/tenant')
      .then(({ data }) => {
        const result = [[]];
        let sequenceOfColumns = ['Name', 'Work Space Creation', 'Suspension Date', 'Period State', 'User Limit'];
        const listName = 'Tenant Details';
        result[0][0] = Object.keys(data).map((tenant) => {
          const obj = {};
          obj['Name'] = data[tenant]['name'];
          obj['Work Space Creation'] = moment(new Date(data[tenant]['timestamp_create'])).format('YYYY-MM-DD');
          obj['Suspension Date'] = moment(new Date(data[tenant]['suspended_at'])).format('YYYY-MM-DD');
          obj['Period State'] = data[tenant]['period_state'] === 0 ? 'Trial' : data[tenant]['period_state'] === 1 ? 'Trial Failed' : data[tenant]['period_state'] === 2 ? 'Paid' : data[tenant]['period_state'] === 3 ? 'Renewal failed' : '';
          obj['User Limit'] = data[tenant]['user_limit'];
          return obj;
        });
        exportAsExcelFile(
          result,
          sequenceOfColumns,
          'Tenant Details',
          [listName]
        );
        this.setState({ tenantRequest: false });
      })
      .catch((err) => {
        console.log({ err });
        this.setState({ tenantRequest: false });
      });
  };

  loginImpersonate = (tenantName) => {
    let reason = this.state.reason;
    axios
      .post(`/tenant/impersonate`, {
        tenantName,
        reason

      }).then((data) => {
        window.open(`https://${tenantName}.flowace.in/main?impersonationCode=${data.data.code}`, '_target')
        this.setState({showDialog: false});
      })
  }

  onCheckboxCheck = (index, data) => {
    this.setState((prevState) => {
      const newCheckboxValues = [...prevState.checkBoxValues];
      newCheckboxValues[index] = !newCheckboxValues[index];
      const selectedData = [...prevState.selectedSchema];
      if(newCheckboxValues[index] === true) {
        selectedData.push(data);
      } else if(newCheckboxValues[index] === false) {
        let index = selectedData.indexOf(data);
        selectedData.splice(index, 1);
      }
      return {checkBoxValues: newCheckboxValues, selectedSchema: selectedData, floatButton: selectedData.length ? true : false};
    })
  };

  handleClick = (value) => {
    this.state.openMenu = !this.state.openMenu;
    let obj = {[value]: this.state.openMenu};
    this.setState({ openDrop: obj });
  }

  openDialog = (value) => {
    this.state.showDialog = true;
    this.setState({ showDialog: true, tenantName: value, reason: '', isDisabled: true });
  }

  closeDialog = () => {
    this.state.showDialog = false;
    this.setState({ showDialog: false });
  }

  handleReasonChange = (e) => {
    if (e.target.value || e.target.value === '') {
      let value = e.target.value;
      this.state.showDialog = true;
      this.setState({ reason: value });
    }
    if (!e.target.value) {
      this.setState({
        errors: {
          ...this.state.errors,
          reason_field: 'Please Enter a Reason',
        },
        isDisabled: true
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          reason_field: null,
        },
        isDisabled: false
      });
    }
  }

  render() {
    const columns = [
      // {
      //   Header: ``,
      //   accessor: `schema`,
      //   width: 80,
      //   Cell: (props) => {
      //     return(
      //       <span style={{position: 'relative', left: '-18px'}} key={props.index}>
      //         <Checkbox 
      //           id={`check-box-${props.index}`} 
      //           color="primary"
      //           checked={this.state.checkBoxValues[props.index] || false} 
      //           onChange={() => this.onCheckboxCheck(props.index, props.value)}
      //         />
      //       </span>
      //     )
      //   }
      // },
      {
        Header: `Name`,
        accessor: 'name',
        Footer: (props) => {
          return <b style={{ float: 'left', paddingLeft: '34px' }}>Total</b>;
        },
      },
      {
        Header: 'Work Space Creation',
        accessor: 'timestamp_create',
        width: 230,
        Cell: (props) => {
          return moment(new Date(props.value)).format('YYYY-MM-DD');
        },
      },
      {
        Header: 'Suspension date',
        accessor: 'suspended_at',
        width: 230,
        Cell: (props) => {
          return moment(new Date(props.value)).format('YYYY-MM-DD');
        },
      },
      {
        Header: 'Period',
        accessor: 'period_state',
        Cell: (props) => {
          return (
            <span>
              {props.value === 0 ? 'Trial' : ''}
              {props.value === 1 ? 'Trial Failed' : ''}
              {props.value === 2 ? 'Paid' : ''}
              {props.value === 3 ? 'Renewal failed' : ''}
            </span>
          )
        }
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Cell: (props) => {
      //     return (
      //       <span style={{ height: 4 }} className={props.value === 'active' ? 'status green' : 'status red'}>
      //         {props.value}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: 'User Limit',
        accessor: 'user_limit',
        Cell: (props) => {
          return <span style={{ float: 'left' }}>{props.value}</span>;
        },
        Footer: (props) => {
          return <b style={{ float: 'left', paddingLeft: '34px' }}>{this.state.totalUsers}</b>;
        },
      },
      {
        Header: '',
        accessor: 'name',
        width: 100,
        Cell: (props) => {
          return (
            <span key={props.index}>
              <div style={{cursor: 'pointer'}} onClick={() => {this.handleClick(props.value)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.24963 4.99963C9.24963 3.47963 10.4796 2.24963 11.9996 2.24963C13.5196 2.24963 14.7496 3.47963 14.7496 4.99963C14.7496 6.51963 13.5196 7.74963 11.9996 7.74963C10.4796 7.74963 9.24963 6.51963 9.24963 4.99963ZM13.2496 4.99963C13.2496 4.30963 12.6896 3.74963 11.9996 3.74963C11.3096 3.74963 10.7496 4.30963 10.7496 4.99963C10.7496 5.68963 11.3096 6.24963 11.9996 6.24963C12.6896 6.24963 13.2496 5.68963 13.2496 4.99963Z"
                    fill="black"
                    fillOpacity="0.87"
                  />
                  <path
                    d="M9.24963 18.9996C9.24963 17.4796 10.4796 16.2496 11.9996 16.2496C13.5196 16.2496 14.7496 17.4796 14.7496 18.9996C14.7496 20.5196 13.5196 21.7496 11.9996 21.7496C10.4796 21.7496 9.24963 20.5196 9.24963 18.9996ZM13.2496 18.9996C13.2496 18.3096 12.6896 17.7496 11.9996 17.7496C11.3096 17.7496 10.7496 18.3096 10.7496 18.9996C10.7496 19.6896 11.3096 20.2496 11.9996 20.2496C12.6896 20.2496 13.2496 19.6896 13.2496 18.9996Z"
                    fill="black"
                    fillOpacity="0.87"
                  />
                  <path
                    d="M9.24963 11.9996C9.24963 10.4796 10.4796 9.24963 11.9996 9.24963C13.5196 9.24963 14.7496 10.4796 14.7496 11.9996C14.7496 13.5196 13.5196 14.7496 11.9996 14.7496C10.4796 14.7496 9.24963 13.5196 9.24963 11.9996ZM13.2496 11.9996C13.2496 11.3096 12.6896 10.7496 11.9996 10.7496C11.3096 10.7496 10.7496 11.3096 10.7496 11.9996C10.7496 12.6896 11.3096 13.2496 11.9996 13.2496C12.6896 13.2496 13.2496 12.6896 13.2496 11.9996Z"
                    fill="black"
                    fillOpacity="0.87"
                  />
                </svg>
              </div>
              {this.state.openDrop[`${props.value}`] &&
                <div className='action-drop'>
                  <div 
                    className='option-list'
                    onClick={() => {
                      this.props.history.push(`/tenant/${props.value}`)
                    }}
                  >
                    Edit
                  </div>
                  <div 
                    className='option-list'
                    onClick={() => {
                      this.props.history.push(`/plugin/${props.original.schema}`)
                    }}
                  >
                    Plugin Release
                  </div>
                  <div 
                    className='option-list'
                    onClick={() => {
                      this.openDialog(props.value)
                    }}  
                  >
                    Impersonation
                  </div>
                </div>
              }
            </span>
          );
        }
      }
    ];
    const { tenantRequest, floatButton, showDialog } = this.state;
    return (
      <div>
        {this.state.data ? (
          <div className="tenant">
            <div className="table-wrapper">
              <div className="pre-table-div">
                <div className="pre-table-div-1">
                  <p>
                    Tenants{' '}
                    <span className="tenant-count">
                      {this.state.data && this.state.data.length !== 0 ? this.state.data.length : ''}
                    </span>
                  </p>
                </div>
                <div className="pre-table-div-2">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">Search...</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      onChange={(e) => this.handleTenantSearch(e.target.value)}
                      startAdornment={
                        !this.state.fetchingLoader ? (
                          <InputAdornment position="start">
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="start">
                            <IconButton>
                              <div className="inlineSearchLoader">
                                <ColorCircularProgress />
                              </div>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      labelWidth={60}
                    />
                  </FormControl>

                  {/* <input type='text' id='search' className='form-input' placeholder='Search with name and status' aria-label='search' name='search'
                                            
                                        /> */}
                  <Tooltip TransitionComponent={Zoom} title="Add new tenant" placement="top">
                    <button type="button" className="div-button" id="add-new-button" onClick={this.addNewTenant}>
                      <span>+</span>
                    </button>
                  </Tooltip>
                  <div className="button-wrapper-with-margin">
                        <Button
                            color="primary"
                            variant="contained"
                            id="button-save"
                            disabled={tenantRequest}
                            onClick={this.exportTenants}
                        >
                            {tenantRequest ? 'Wait...' : 'Export'}
                        </Button>
                    </div>
                </div>
              </div>
              <ReactTable
                data={this.state.data}
                columns={columns}
                minRows={0}
                showPagination={this.state.data && this.state.data.length > 15 ? true : false}
                showPaginationTop={false}
                showPaginationBottom={this.state.data && this.state.data.length > 15 ? true : false}
                showPageSizeOptions={true}
                // getTrProps={(state, rowInfo) => {
                //   if (rowInfo && rowInfo.row) {
                //     return {
                //       onClick: (e) => {
                //         this.props.history.push(`/tenant/${rowInfo.row.name}`);
                //         this.setState({
                //           selected: rowInfo.index,
                //           hovereed: rowInfo.index,
                //         });
                //       },
                //       onMouseOver: (e) => {
                //         this.setState({
                //           hovereed: rowInfo.index,
                //         });
                //       },
                //       style: {
                //         background: rowInfo.index === this.state.hovereed ? '#f7f7f7' : 'white',
                //       },
                //     };
                //   } else {
                //     return {};
                //   }
                // }}
              />
            </div>
            <Dialog open={showDialog} onClose={() => {this.closeDialog()}}>
              <DialogTitle>Impersonation</DialogTitle>
              <DialogContent>
              <label htmlFor="reason" className={`form-label`} style={{color: this.state.errors.reason_field ? 'red' : 'rgba(64, 64, 64, 0.9)'}}>
                Reason
              </label>
              <input
                style={{width: '400px'}}
                type="text"
                id="reason"
                className={`form-input ${this.state.errors.reason_field ? 'error' : ''}`}
                placeholder="Type Reason"
                aria-label="reason"
                name="reason"
                onChange={(e) => {this.handleReasonChange(e)}}
              />
              {this.state.errors.reason_field && (
                <label htmlFor="reason" className={`form-label-error`}>
                  {this.state.errors.reason_field}
                </label>
              )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {this.closeDialog()}}>
                  Close
                </Button>
                <div className="button-wrapper-with-margin">
                  <Button 
                    color="primary"
                    variant="contained"
                    id="button-save"
                    disabled={this.state.isDisabled}
                    onClick={() => {this.loginImpersonate(this.state.tenantName)}}
                  >
                    Impersonation
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
            <div 
              className='float-button' 
              style={{display: floatButton === true ? 'flex' : 'none'}}
              onClick={() => {
                this.props.history.push(`/multi-tenant/${this.state.selectedSchema.toString().split(',').join('&') }`);
              }}
            >
              Multi Tenant
            </div>
          </div>
        ) : (
          <div className="progressBar">
            <ColorCircularProgress />
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}

export default TenantList;
