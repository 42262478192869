import React, { Component } from 'react'
import { NavLink, Redirect, withRouter } from 'react-router-dom'

import '../stylesheets/navbar.scss'

// Tooltip
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Navbar extends Component {


    state = {
        name: cookies.get('name')
    }


    constructor() {
        super()
        this.ismenuHidden = true;
    }
    signOut = () => {
        cookies.remove('token');
        this.props.history.push(`/login`);
    }

    hideMenu = () => {
        this.ismenuHidden = true
        const menuMain = document.getElementById("menuMain")
        const line = document.getElementsByClassName("line");
        const crumb = document.getElementsByClassName("crumb");
        menuMain.classList.remove("show-menu");
        Array.prototype.forEach.call(line, function (element) {
            element.classList.remove("cross")
        });
        Array.prototype.forEach.call(crumb, function (element) {
            element.classList.remove("animate-left")
        });
    }

    showMenu = () => {
        this.ismenuHidden = false
        const menuMain = document.getElementById("menuMain")
        const line = document.getElementsByClassName("line");
        const crumb = document.getElementsByClassName("crumb");
        menuMain.classList.add("show-menu");
        Array.prototype.forEach.call(line, function (element) {
            element.classList.add("cross")
        });
        Array.prototype.forEach.call(crumb, function (element, index, array) {
            console.log(array.length)
            setTimeout(function () {
                element.classList.add("animate-left")
            }, index * 200);
        });
    }

    toggleMenu = () => {
        if (this.ismenuHidden) {
            this.showMenu();
        } else {
            this.hideMenu();
        }
    }



    render() {
        if (!cookies.get('token')) {
            return (<Redirect to={'/login'} />)
        }

        return (
            <div className="navbar">
                <nav>
                    <li className="logo">
                        <NavLink className='nav-link' to="/">
                            <img className='logo-image-nav' src={require('../assets/flowace.png')} alt='flowace' />
                        </NavLink>
                    </li>
                    {/** # # # ------- Commented
                    <div className="hamburger" onClick={this.toggleMenu}>
                        <div className="menu-div large-hidden" id="menuToggle">
                            <div className="line line1" id="iconline1"></div>
                            <div className="line line2" id="iconline2"></div>
                            <div className="line line3" id="iconline3"></div>
                        </div>
                    </div>
                    # # # ------- Commented **/}
                    <ol className="menu" id="menuMain">
                        <div>
                            <div className="crumb">
                                <NavLink className='nav-link' to="/tenant">Tenants</NavLink>
                            </div>
                        </div>
                        {/* <div>
                            <div className="crumb">
                                <NavLink className='nav-link' to="/xray">X-Ray</NavLink>
                            </div>
                        </div> */}
                        <div>
                            <div className="crumb">
                                <NavLink className='nav-link' to="/other">Other</NavLink>
                            </div>
                        </div>
                        <div>
                            <div className="crumb">
                                <NavLink className='nav-link' to="/admin-emails">Admin Emails</NavLink>
                            </div>
                        </div>
                        <div>
                            <div className="crumb">
                                <NavLink className='nav-link' to="/status">Status</NavLink>
                            </div>
                        </div>
                    </ol>
                    <li className='profile-name' >
                        <div>
                            <span className='profile-name-text'>
                                {'Hi, ' + this.state.name.split(' ')[0]}
                            </span>
                            <Tooltip TransitionComponent={Zoom} title="Sign Out" placement="top">
                                <span><img onClick={this.signOut} className='sign-out-img' src={require('../assets/sign-out.png')} alt='Sign out' /></span>
                            </Tooltip>
                            
                        </div>
                    </li>
                </nav>
            </div>
        )
    }
}

export default withRouter(Navbar)
