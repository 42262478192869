import React, { Component } from 'react';
import axios from '../services/axios';
import '../stylesheets/form.scss';
import '../stylesheets/tenant.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from './breadcrumb';

// Buttons
import Button from '@material-ui/core/Button';

// Loader
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const ColorCircularProgress = withStyles({
  root: {
    color: '#f07539',
  },
})(CircularProgress);

class MultiTenant extends Component {
  state = {
    data: null,
    isDisabled: true,
    errors: {
      windows_plugin: null,
      stealth_mode: null,
      ubuntu_plugin: null
    },
    schemaNames: null,
  };
  originalState = {
    data: null,
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const schemaNames = params.name.split('&').join(', ')
    const maxLenght = 25;
    const display = schemaNames.length > maxLenght ? `${schemaNames.slice(0, maxLenght)}...` : schemaNames;
    this.setState({schemaNames: display});
    const schemaName = params.name.split('&');
    schemaName.forEach(ele => {
        axios.get(`/configuration/${ele}/PLUGIN_LATEST_RELEASE`).then((response) => {
            if(response && response.data) {
                this.setState({
                  data: JSON.parse(response.data[0].value),
                });
                this.originalState.data = JSON.parse(response.data[0].value);
            }
        });
    })
  }

  canBeSubmitted = () => {
    const localState = this.state.data;
    const localOriginalState = this.originalState.data;
    let isDisabled = false;
    for (const key in this.state.errors) {
      if (this.state.errors[key] !== null) {
        isDisabled = true;
        break;
      }
    }
    if (isDisabled) {
      this.setState({ isDisabled: true });
      return;
    }

    if (
      localState.value === localOriginalState.value &&
      localState.stealth === localOriginalState.stealth &&
      localState.ubuntu === localOriginalState.ubuntu
    ) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isDisabled: false });
    }
  };

  handleCancelClick = () => {
    this.props.history.push(`/tenant`);
  };

  handleSaveClick = () => {
    const {
      match: { params },
    } = this.props;
    const localState = this.state.data;
    const localOriginalState = this.originalState.data;
    let body = localOriginalState;
    for (let key in localOriginalState) {
      if (localState[key] !== localOriginalState[key]) {
        if (key === 'value') {
          body['value'] = this.state.data['value'];
        } else if (key === 'stealth') {
          body['stealth'] = this.state.data['stealth'];
        } else if (key === 'ubuntu') {
          body['ubuntu'] = this.state.data['ubuntu'];
        } else if (key === 'mac') {
          body['mac'] = this.state.data['mac'];
        }
      }
    }
    const schemaName = params.name.split('&');
    schemaName.forEach(ele => {
        axios
        .post('/configuration/update', {
            schema: `${ele}`,
            key: 'PLUGIN_LATEST_RELEASE',
            value: body
        })
        .then((response) => {
            this.setState({
            isDisabled: true
            });
            toast.success('Changes saved !', {
            position: toast.POSITION.BOTTOM_RIGHT,
            });
        })
        .catch((e) => {
            let error = 'Generic Error';
            if (e) {
            console.log(e, e.response)
            if (e && e.response && e.response.data && e.response.data.error && e.response.data.error.message) {
                if (Array.isArray(e.response.data.error.message)) {
                let message = '';
                for (let item of e.response.data.error.message) {
                    for (let o in item) {
                    message += `${o} : ${item[o]}\n `;
                    }
                }
                error = message;
                } else {
                error = e.response.data.error.message;
                }
            } else {
                error = e.message;
            }
            }
            toast.error(error+'asd', {
            position: toast.POSITION.BOTTOM_RIGHT,
            });
        });
    });
  };

  handleWindowsPluginChange = (e) => {
    if (e.target.value || e.target.value === '') {
      let value = e.target.value;
      this.setState({ data: { ...this.state.data, value: value } }, this.canBeSubmitted);
    }
  };

  handleStealthModeChange = (e) => {
    if (e.target.value || e.target.value === '') {
      let value = e.target.value;
      this.setState({ data: { ...this.state.data, stealth: value } }, this.canBeSubmitted);
    }
  }

  handleUbuntuPluginChange = (e) => {
    if (e.target.value || e.target.value === '') {
      let value = e.target.value;
      this.setState({ data: { ...this.state.data, ubuntu: value } }, this.canBeSubmitted);
    }
  }

  handleMACPluginChange = (e) => {
    if (e.target.value || e.target.value === '') {
      let value = e.target.value;
      this.setState({ data: { ...this.state.data, mac: value } }, this.canBeSubmitted);
    }
  }

  render() {
    return (
      <div className="page-container">
        {this.state.data ? (
          <>
            <Breadcrumb
              data={[
                {
                  text: 'Multi Tenant',
                  link: '/tenant',
                },
                {
                  text: this.state.schemaNames,
                  link: null,
                },
              ]}
            />
            <div className="tenant-page-container">
              <form className="tenant-page-form">
                <div className="form-item">
                  <label htmlFor="schemaName" className={`form-label`}>
                    Schema name
                  </label>
                  <input
                    disabled
                    type="text"
                    id="schemaName"
                    className={`form-input`}
                    placeholder="schemaName"
                    aria-label="schemaName"
                    name="schemaName"
                    value={this.props.match.params.name.split('&').join(', ')}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="windows" className={`form-label`}>
                    Windows Plugin
                  </label>
                  <input
                    type="text"
                    id="windows"
                    className={`form-input`}
                    placeholder="Windows Plugin"
                    aria-label="windows"
                    name="windows"
                    onChange={this.handleWindowsPluginChange}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="stealth_mode" className={`form-label`}>
                    Stealth Mode
                  </label>
                  <input
                    type="text"
                    id="stealth_mode"
                    className={`form-input`}
                    placeholder="Stealth mode"
                    aria-label="stealth_mode"
                    name="stealth_mode"
                    onChange={this.handleStealthModeChange}
                  />
                </div>

                <div className="form-item">
                  <label htmlFor="ubuntu">
                    Ubuntu
                  </label>
                  <input
                    type="text"
                    id="ubuntu"
                    className={`form-input`}
                    placeholder="Ubuntu"
                    aria-label="ubuntu"
                    name="ubuntu"
                    onChange={this.handleUbuntuPluginChange}
                  />
                </div>

                <div className="form-item">
                  <label htmlFor="ubuntu">
                    Mac
                  </label>
                  <input
                    type="text"
                    id="mac"
                    className={`form-input`}
                    placeholder="Mac"
                    aria-label="mac"
                    name="mac"
                    onChange={this.handleMACPluginChange}
                  />
                </div>
                <div className="form-item button">
                  <div className="button-wrapper-with-margin">
                    <Button color="default" onClick={this.handleCancelClick}>
                      Cancel
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      id="button-save"
                      disabled={this.state.isDisabled}
                      onClick={this.handleSaveClick}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <ToastContainer autoClose={4000} />
          </>
         ) : (
          <div className="progressBar">
            <ColorCircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default MultiTenant;
