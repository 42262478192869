import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

function exportAsExcelFile(json, a, excelFileName, sheetsName = []) {
  return new Promise(async (resolve, reject) => {
    try {
      const workbook = XLSX.utils.book_new();
      workbook.Props = {
        Title: excelFileName,
        Author: 'Flowace',
        CreatedDate: new Date(),
      };
      const worksheet = [];
      const obj = {};
      const keyMax = [];

      sheetsName.forEach((data, index) => {
        worksheet[index] = XLSX.utils.json_to_sheet([{}]);
        XLSX.utils.sheet_add_json(worksheet[index], json[0][index], { origin: 'A3' });
        XLSX.utils.sheet_add_aoa(worksheet[index], [['']], {
          origin: -1,
        });
        XLSX.utils.sheet_add_aoa(worksheet[index], [['Generated by Flowace Team. http://www.flowace.in']], {
          origin: -1,
        });
        let mydata = json[0][index];
        workbook.SheetNames.push(sheetsName[index]);
        obj[data] = worksheet[index];
        const noOfCols = Object.keys(json[0][index][0] || []).length;
        const noOfRows = json[0][index].length;
        const wscols = [];

        Object.keys(json[0][index][0] || []).forEach((key, index) => {
          keyMax[key] = allValues(key, mydata);
          let maxLen = 0;
          keyMax[key].forEach((row) => {
            if (row) {
              if (row.length > maxLen) {
                maxLen = row.length;
                keyMax[key] = maxLen;
              }
            }
          });
        });
        Object.keys(keyMax).forEach((key, index) => {
          const v = keyMax[a[index]] < key.length ? key.length : keyMax[a[index]];
          const newObj = { wch: v < 25 ? v + 2 : 30 };
          wscols[index] = newObj;
        });

        worksheet[index]['!cols'] = wscols;
        const mRow = noOfRows + 3;
        const merge = [{ s: { r: mRow, c: 0 }, e: { r: mRow, c: noOfCols - 1 } }];
        worksheet[index]['!merges'] = merge;
      });

      workbook.Sheets = obj;
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      await saveAsExcelFile(excelBuffer, excelFileName);
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
}

function allValues(key, mydata) {
  const mykey = key;
  const allRows = mydata.reduce((accumulator, currentValue) => {
    for (const keys in currentValue) {
      if (keys === mykey) {
        return accumulator.concat(currentValue[key]);
      }
    }
  }, []);
  return allRows;
}

function saveAsExcelFile(buffer, fileName) {
  return new Promise((resolve, reject) => {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    resolve(true);
  });
}
export default exportAsExcelFile;
