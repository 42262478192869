import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from '../services/axios';
import Cookies from 'universal-cookie';
import '../stylesheets/form.scss'
import '../stylesheets/login.scss'
import jwt from 'jsonwebtoken'

// Buttons
import Button from '@material-ui/core/Button';

import { ToastContainer, toast } from 'react-toastify';

const cookies = new Cookies();

class Login extends Component {
    state = {
        username: '',
        password: '',
        redirect: cookies.get('token') ? true : false
    }

    handleLoginClick = () => {
        this.toastId = toast(" ⏳ Loggin In. Please wait...", { 
            autoClose: false ,
            type: toast.TYPE.INFO,
            position: toast.POSITION.BOTTOM_RIGHT
        });
        if (this.state.username && this.state.password) {
            axios.post('/login', {
                "username": this.state.username,
                "password": this.state.password
            })
            .then((response) => {
                cookies.set('token', response.data.token, { path: '/' })
                let data = jwt.decode(response.data.token);
                for (let key in data) {
                    cookies.set(key.toString(), data[key], { path: '/' })
                }
                toast.update(this.toastId, {
                    render : " 🚀 Login Successfully!",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 500 ,
                    onClose: () => this.setState({ redirect: true })
                });
                
            })
            .catch((error) => {
                console.error(error)
                toast.update(this.toastId, {
                    render : '⚠️ Login Failed!',
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR,
                    autoClose: false
                });
            });
        } else {
            toast.error("Required fields can't be empty.", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
        let elem = document.getElementById('password')
        this.validate(elem, event)
    }

    handleUsernameChange = (event) => {
        this.setState({ username: event.target.value });
        let elem = document.getElementById('username')
        this.validate(elem, event)
    }

    validate(elem, event) {
        if (!event.target.value) {
            if (!elem.classList.contains('input-danger')) {
                elem.classList.add('input-danger');
            }
        } else {
            if (elem.classList.contains('input-danger')) {
                elem.classList.remove('input-danger');
            }
        }
    }

    componentWillMount() {
        console.log(this.state)
        // cookies.get('token') ? true : false
    }

    render() {
        console.log('the redirect state is :: ', this.state.redirect)
        if (this.state.redirect) {
            console.log('redirecting to home page')
            return (<Redirect to={'/'} />)
        }
        return (
            <div className='login-container'>
                <div className='login-card'>
                    <h1> <img className='logo-image' src={require('../assets/flowace.png')} alt='flowace' />  | manage</h1>
                    <form className="login-form">
                        <div className='form-item'>
                            <label htmlFor="username" className='form-label'>Username<sup>*</sup></label>
                            <input type='text' id='username' className='form-input' onChange={this.handleUsernameChange} placeholder='username' aria-label='username' name='username' />
                        </div>
                        <div className='form-item'>
                            <label htmlFor="password" className='form-label'>Password</label>
                            <input type='password' id='password' className='form-input' onChange={this.handlePasswordChange} placeholder='password' aria-label='password' name='password' />
                        </div>
                        <div className='button-wrapper-with-margin login-button'>
                            <Button color="primary" variant="contained" id='button-save' onClick={this.handleLoginClick}>
                              login
                            </Button>
                        </div>
                    </form>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

export default Login
