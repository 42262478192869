import React, { Component } from 'react';
import GridLayout from 'react-grid-layout';

import Highcharts from 'highcharts'
import PieChart from 'highcharts-react-official'
// import BarChart from 'highcharts-react-official'

import axios from '../services/axios';

import '../stylesheets/react-grid-layout.css';
import '../stylesheets/react-resizeable.css';
import '../stylesheets/dashboard.scss';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        let ls = {};
        if (global.localStorage) {
          ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
          console.log(ls);
          if(!ls.layout) {
            this.state = {
                layout:[
                    {"w":6,"h":12,"x":0,"y":0,"i":"WA","moved":false,"static":false},
                    {"w":9,"h":5,"x":0,"y":12,"i":"WB","moved":false,"static":false},
                    {"w":6,"h":12,"x":6,"y":0,"i":"WC","moved":false,"static":false},
                    {"w":3,"h":5,"x":9,"y":12,"i":"WD","moved":false,"static":false}
                ]
              };
          } else {
            this.state = { layout: ls.layout };
        }
          console.log(this.state, 1);
        }

        this.state.widgetAOptions = {
            chart: {
                type: "pie"
            },
          title: {
            text: 'Users per tenant'
          },
          series: [{
                name: 'Brands',
                colorByPoint: true,
                data: []
            }]
        }
    }

    componentDidMount() {
        this.getUsersPerTenant();
    }

    getUsersPerTenant(){
        axios.get(`/analytics/getUsersPerTenant`)
            .then(response => {
                let totalUsersOnTenants = response.data.reduce((a, b) => a + b.user_limit,0);
                let result = response.data.map(tenant=>{ return { name : tenant.name, y : tenant.user_limit}; });
                console.log(totalUsersOnTenants);
                // if(response.data.doesExist){
                    this.setState({
                        totalUsersOnTenants,
                        widgetAOptions: { 
                            title: {
                                text : this.state.widgetAOptions.title.text + ` (${totalUsersOnTenants}) `
                            },
                            series : { data : result} 
                        }
                    });
                // }else{
                //     this.setState({isDisabled:false})
                // }
            })
            .catch(e => {
                console.log(e);
                // this.setState({isDisabled:true})
            })
        // this.setState({ data: { ...this.state.data, name: e.target.value } })
    }

    onLayoutChange(layout) {
        console.log('yo');
        /*eslint no-console: 0*/
        if (global.localStorage) {
            global.localStorage.setItem(
              "rgl-7",
              JSON.stringify({
                layout: layout
              })
            );
        }
    }

    render() {

        return (
            <div className='page-container'>
             <GridLayout className="layout" layout={this.state.layout} cols={12} rowHeight={30} width={1410} onLayoutChange={this.onLayoutChange}>
                <div key="WA">
                    <div className="dashboard-widget-item">
                        <PieChart
                            highcharts={Highcharts}
                            options={this.state.widgetAOptions}
                        />
                    </div>
                </div>
                
              </GridLayout>
            </div>
        )
    }
}

export default Dashboard
